/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Cookies from 'js-cookie'

console.log('Hello World from Webpacker')

document.addEventListener('DOMContentLoaded', function() {
  if($('#video_container').length > 0){
    $.ajax({
      type: 'GET',
      dataType: "json",
      url: "https://vimeo.com/api/oembed.json?responsive=true&url=" + $('#video_container').attr('data-id'),
      success: function (responseData, textStatus, jqXHR) {
          console.log("in");
          // var data = JSON.parse(responseData);
          // console.log(responseData.html);
          $('#video_container').html(responseData.html);
      },
      error: function (responseData, textStatus, errorThrown) {
          console.log('GET failed.');
      }
    });
  }

  $('#announcement_alert').on('closed.bs.alert', function () {
    // console.log($('#announcement_alert_digest').val());
    var alert_ids = Cookies.get('announcements_closed');
    console.log($(this).attr('data-id'));
    console.log(alert_ids);
    if(alert_ids === undefined) {
      alert_ids = $(this).attr('data-id');
    } else {
      alert_ids = alert_ids.concat(",");
      alert_ids = alert_ids.concat($(this).attr('data-id'));
    }
    console.log(alert_ids);
    Cookies.set(
      'announcements_closed',
      alert_ids,
      { expires: (365*7), 'SameSite': 'Lax' }
    ); 
  })

});